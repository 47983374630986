.ticker {
    @keyframes ticker-left {
        from { transform: translateX(0); }
        to { transform: translateX(-50%); }
    }

    &:hover &__wrapper, &:focus &__wrapper, &:focus-within &__wrapper {
        animation-play-state: paused;
    }

    &__wrapper {
        will-change: transform;
        animation: ticker-left 60s linear infinite;
        @media screen and (prefers-reduced-motion: reduce), (update: slow) {
            animation: none;
        }
    }
}
