.wysiwyg {
    p:not(.ingress), ul, ol {
        margin-bottom: calc((24/16) * 1em);

        &:last-child {
            margin-bottom: 0;
        }

        br:only-child {
            //display: none;
        }
    }

    .ingress ~ p {
        padding-right: 9%;
    }

    ul {
        padding-left: 1em;
        list-style: disc;
        li {
            display: list-item;
        }
    }

    ol {
        list-style: none;
        counter-reset: ol-counter;
        li {
            counter-increment: ol-counter;
            display: flex;
            &:before {
                content: counter(ol-counter) " ";
                font-weight: 500;
                font-size: 0.9em;
                margin-top: 0.1em;
                margin-right: 0.6em;
            }
        }
    }

    li {
        margin-bottom: 0.5em;
        &:last-child {
            margin-bottom: 0;
        }
    }

    table {
        line-height: 1.3;
        margin-bottom: calc((24/16) * 1em);
        td {
            padding-right: 25px;
        }
    }

    h2, h3 {
        @apply font-semibold text-20 m:text-27 leading-1_3 mb-[0.75em];
    }

    b, strong {
        @apply font-medium;
    }

    i, em {
        @apply italic;
    }
}
