@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 208, 132, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(255, 208, 132, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(255, 208, 132, 0);
    }
}

.hotspot {
    position: absolute;
    &.js-is-active {
        z-index: 1;
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transform: translate(-50%, -50%);
        @apply text-green;
        @apply transition-all duration-300 ease-in-out-circ;

        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            border-radius: 50%;
            @apply bg-orange;
            transform: scale(1);
            animation: pulse 2s infinite;
            transition: all 0.25s ease-in-out;
            box-shadow: 0 0 0 0 rgba(255, 208, 132, 0);
        }
        span {
            font-size: 14px;
            font-weight: 700;
            line-height: 1;
        }
        svg {
            position: relative;
            z-index: 1;
        }
        &:hover:before {
            animation: none;
            box-shadow: 0 0 0 4px rgba(255, 208, 132, 0.2);
        }
        &[aria-expanded="true"] {
            color: $colorYellow;
            @apply text-orange;
            &:before {
                animation: none;
                @apply bg-green;
                box-shadow: 0 0 0 2px #FFD084;
            }
        }
        &--icon[aria-expanded="true"] {
            transform: translate(-50%, -50%) rotate(135deg);
        }
    }
    &__dialog {
        position: absolute;
        display: flex;
        width: 220px;
        top: -20px;
        left: 30px;
        &.left {
            left: auto;
            right: calc(100% + 30px);
            justify-content: flex-end;
        }
        &-wrapper {
            @apply text-green bg-orange;
            font-size: 14px;
            line-height: 1.3;
            border-radius: 4px;
            padding: 15px 20px;
        }
    }
}
