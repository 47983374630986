html {
    outline: none !important;
    &.js-menu-open {
        background: #FFD084;
    }
    &.js-at-bottom:not(.js-menu-open) {
        background: #132B1B;
    }
}
body {
    padding-top: 165px;
    @screen m {
        padding-top: 237px;
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    @apply transition-opacity duration-450 ease-in-sine;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

.links, .wysiwyg {
    a {
        border-bottom: 1px solid currentColor;
        @apply transition-colors duration-300 ease-out-quad;

        html.using-mouse &:hover,
        html.outline &:focus {
            border-bottom-color: transparent;
        }
    }
}

.links--inverted {
    a {
        border-bottom: 1px solid transparent;
        @apply transition-colors duration-300 ease-out-quad;

        html.using-mouse &:hover,
        html.outline &:focus {
            border-bottom-color: currentColor;
        }
    }
}

.links--external {
    a[target='_blank']{
        &:after {
            content: '\00a0→';
            display: inline-block;
            transition: all 0.25s ease-out;
        }
        html.using-mouse &:hover:not(:disabled),
        html.outline &:focus:not(:disabled) {
            &:after {
                transform: translateX(4px);
            }
        }
    }
}

a, button {
    .arrow-left, .arrow-right, .arrow-up-right, .arrow-up, .arrow-down {
        display: inline-block;
        transition: all 0.25s ease-out;
    }
    html.using-mouse &:hover:not(:disabled),
    html.outline &:focus:not(:disabled) {
        .arrow-left {
            transform: translateX(-4px);
        }
        .arrow-right {
            transform: translateX(4px);
        }
        .arrow-up-right {
            transform: translate(4px, -4px);
        }
        .arrow-up {
            transform: translateY(-4px);
        }
        .arrow-down {
            transform: translateY(4px);
        }
    }
}

.image-zoom {
    img, video {
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        max-width: none;
    }
    img {
        will-change: opacity, transform !important;
        transition: transform 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940), opacity 0.45s cubic-bezier(0.470, 0.000, 0.745, 0.715) !important;
    }
    html.using-mouse &:hover,
    html.outline &:focus {
        img {
            transform: scale(1.0175);
        }
    }
}

[hidden] {
    display: none !important;
}

html.js {
    [data-reveal] {
        opacity: 0;
        transform: translateY(5vh);
        will-change: opacity, transform;
    }
}
