@layer base {

    /**
        @font-face declarations
        Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"
     */

    @font-face {
        font-family: 'Graphik';
        src: url('../fonts/Graphik-Regular-Web.woff2') format('woff2'), url('../fonts/Graphik-Regular-Web.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('../fonts/Graphik-RegularItalic-Web.woff2') format('woff2'), url('../fonts/Graphik-RegularItalic-Web.woff') format('woff');
        font-weight: 400;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('../fonts/Graphik-Medium-Web.woff2') format('woff2'), url('../fonts/Graphik-Medium-Web.woff') format('woff');
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('../fonts/Graphik-MediumItalic-Web.woff2') format('woff2'), url('../fonts/Graphik-MediumItalic-Web.woff') format('woff');
        font-weight: 500;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('../fonts/Graphik-Semibold-Web.woff2') format('woff2'), url('../fonts/Graphik-Semibold-Web.woff') format('woff');
        font-weight: 700;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('../fonts/Graphik-SemiboldItalic-Web.woff2') format('woff2'), url('../fonts/Graphik-SemiboldItalic-Web.woff') format('woff');
        font-weight: 700;
        font-style: italic;
        font-display: swap;
    }

    @font-face {
        font-family: 'Graphik';
        src: url('../fonts/Graphik-Bold-Web.woff2') format('woff2'), url('../fonts/Graphik-Bold-Web.woff') format('woff');
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Vestre';
        src: url('../fonts/Vestre.woff2') format('woff2'), url('../fonts/Vestre.woff') format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
}
