@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}
    .h1 {
        @apply font-vestre text-25 m:text-60 leading-1;
    }
    .h2 {
        @apply font-vestre text-23 m:text-60 leading-1;
    }

    .no-br-on-mobile br {
        @screen mobile {
            display: none;
        }
    }

    .ingress {
        @apply text-20 m:text-27 font-medium leading-[1.3] m:leading-[1.33] mb-[1.25em] m:mb-[1.7em] l:mb-[2.4em];
    }

    .news-list {
        @screen l {
            li:nth-child(odd) {
                position: relative;
                &:before {
                    content: '';
                    background: currentColor;
                    position: absolute;
                    top: -1px;
                    right: -50px;
                    width: 100px;
                    height: 1px;
                }
            }
            li:nth-child(even) a {
                border-left: 0;
            }
        }
    }

    .people-list {
        @screen m {
            li:nth-child(3n+1) {
                position: relative;
                &:after {
                    position: absolute;
                    bottom: -58px;
                    left: 0;
                    display: block;
                    content: '';
                    width: 100vw;
                    background: black;
                    height: 1px;
                }
            }
        }
        @screen l {
            li:nth-child(3n+1):after {
                display: none;
            }
            li:nth-child(4n+1) {
                position: relative;
                &:after {
                    position: absolute;
                    bottom: -77px;
                    left: 0;
                    display: block;
                    content: '';
                    width: 100vw;
                    background: black;
                    height: 1px;
                }
            }
        }
    }

    .accordion-button {
        svg {
            transition: transform 0.5s ease-in-out;
            will-change: transform;
        }
        &[aria-expanded="true"] svg {
            transform: rotate(180deg);
        }
    }

    .slideshow-button {
        position: relative;
        width: 40px;
        height: 40px;
        transition: opacity 0.3s ease-out;
        will-change: opacity;
        pointer-events: auto;

        &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: transform 0.3s ease-out;
            will-change: transform;
            z-index: -1;
            @apply bg-orange;
        }

        &:hover:not(:disabled):after,
        &:focus:not(:disabled):after {
            transform: scale(1.05);
        }

        &:disabled {
            opacity: 0;
        }

        @screen m {
            width: 54px;
            height: 54px;
        }

        @screen l {
            width: 100px;
            height: 100px;
        }
    }

    .play-button {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        user-select: none;
        @apply center;


        span {
            display: block;
            text-align: center;
            line-height: 1.3;
            margin-top: -0.2em;
        }

        &:after {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            border-radius: 50%;
            transition: transform 0.3s ease-out;
            will-change: transform;
            z-index: -1;
            @apply bg-orange;
        }

        a:hover &:after, a:focus &:after {
            transform: scale(1.05);
        }

        @screen m {
            width: 110px;
            height: 110px;
        }

        @screen l {
            width: 145px;
            height: 145px;
        }
    }

    .video-embed {
        @apply fixed full flex items-center opacity-0 invisible z-100 bg-black bg-opacity-85;

        iframe {
            @apply absolute full;
        }
    }

    .video-loop {
        [data-image] {
            opacity: 0;
            html.no-js &, .js-cant-play-video & {
                opacity: 1;
            }
        }

        [data-video] {
            html.no-js &, .js-cant-play-video & {
                display: none;
            }
            opacity: 0;
            will-change: opacity;
            transition: opacity 0.35s ease-out;
        }

        &.js-is-playing [data-video] {
            opacity: 1;
        }
    }

    html.js .hero {
        img {
            transform: scale(1.1);
            transition: opacity 0.35s ease-in, transform 3.5s cubic-bezier(0.075, 0.820, 0.165, 1.000);
            &.lazyloaded {
                transform: scale(1);
            }
        }
    }


    .hover {
        @apply transition-colors duration-500 ease-out-quad;
    }

    .hover-orange {
        @apply hover bg-orange focus:bg-orange-dark hover:bg-orange-dark;
    }

    .hover-green-light {
        @apply hover bg-green-light focus:bg-green-hover hover:bg-green-hover;
    }

    .hover-blue-light {
        @apply hover bg-blue-light focus:bg-blue-hover hover:bg-blue-hover;
    }

    .hover-pink-dark {
        @apply hover bg-pink-dark focus:bg-pink-hover hover:bg-pink-hover;
    }
}
