.page-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    visibility: hidden;
    opacity: 0;

    &:target {
        visibility: visible;
        opacity: 1;
    }

}
