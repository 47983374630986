@layer base {

    /* ==============================
        Værsågod HTML reset styles
        This is overriding some of Tailwind's preflight styles
    ============================== */

    html {
        font-size: 100%;
        -ms-text-size-adjust: 100%;
        height: 100%;
    }

    body {
        position: relative;
        font-weight: normal;
        font-size: 16px;
        line-height: calc(24 / 16);
        overflow-anchor: none;
    }

    h1, h2, h3, h4, h5, h6 {
        display: block;
        margin: 0;
    }

    p, small, li {
        display: block;
        margin: 0;
    }

    ul, ol {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    img {
        display: block;
        border: 0;
        width: 100%;
    }

    figure {
        margin: 0;
    }

    a, input, button {
        -ms-touch-action: none !important;
    }

    input {
        border-radius: 0;
    }

    input[type="radio"] {
        //-webkit-appearance: radio;
    }

    input[type="checkbox"] {
        //appearance: none;
    }

    textarea {
        resize: none;
    }

    select {
        border-radius: 0;
    }

    input::-ms-clear {
        display: none;
    }

    input[type="search"], input[type="text"], input[type="email"], textarea {
        appearance: none;
        //border-radius: 0;
        box-sizing: border-box;
    }

    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    picture {
        display: block;
    }

    address {
        font-style: normal;
    }

    b, strong {
        font-weight: normal;
    }

    i, em {
        font-style: normal;
    }

    button:not(:focus) {
        outline: 0;
    }

    .no-outline {
        button, a, input, label, textarea, select, option {
            outline: none !important;
        }
    }

    [class$="debug-toolbar__title"] img {
        width: 30px;
        height: 30px;
    }

}
