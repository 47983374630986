.page-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 106px;
    z-index: 10;

    @screen m {
        height: 156px;
    }

    @screen l {
        height: 170px;
    }

    &.headroom {
        position: fixed;
        will-change: transform, background, color;
        transition: transform 350ms ease-in-out, background 350ms ease-in-out, color 350ms ease-in-out, height 550ms ease-out;
        transform: translateY(0%);
        &.headroom--pinned:not(.headroom--top) {
            @apply text-green;
            background: rgba(254, 240, 230, 0.975);
            transform: translateY(0%);
            height: 70px;
            @screen m {
                height: 80px;
            }

            @screen l {
                height: 90px;
            }
            &:not(.headroom--frozen) {
                [data-menu-toggle] {
                    &.bg-green {
                        @apply text-pink-light;
                    }

                }
            }
        }
        &.headroom--unpinned {
            transform: translateY(-100%);
        }
        &.headroom--scrolled {
            transition: none !important;
        }
        &.headroom--initial {
            transition: transform 1.5s ease-in-out;
        }
    }

    &__logo {
        @screen m {
            width: 187px;
            height: 33px;
        }
    }

    [data-menu-toggle] {
        will-change: color;
        transition: color 350ms ease-in-out;
    }

    [data-plus] {
        will-change: transform;
        transition: transform 350ms ease-in-out;
        html.js-menu-open & {
            transform: rotate(135deg);
        }
    }

    html.js-menu-open & [data-menu-toggle].bg-green {
        @apply text-orange;
    }
}
