.checkmark {
    position: absolute;
    width: 19px;
    height: 19px;
    top: 2px;
    left: 1px;
    display: none;

    input:checked + & {
        display: block;
    }
}
